'use strict';

dashboardController.$inject = [
  '$scope',
  '$localStorage',
  '$uibModal',
  '$window',
  'News',
  'Orders'
];
function dashboardController($scope, $localStorage, $uibModal, $window, News, Orders) {
  $scope.messages = {                                                                                                                                 
    status: '',                                                                                                                                       
    message: ''                                                                                                                                       
  };                                                                                                                                                  
  $scope.noNews = true;
  $scope.isSearched = false;

  News.listAvailableNews().then(
    function onSuccess(result){
      if (angular.isArray(result)) {
        $scope.newsList = result;
        if ( result.length > 0 ) {
          $scope.noNews = false;
        }
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
    },
    function onError(result) {
      $scope.messages.status = 'danger';
      $scope.messages.message = result;
    }
  );

  var _loadOrders = function(page) {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    var keyword = '';
    if ( $scope.searchWord) {
      keyword = encodeURIComponent($scope.searchWord);
    }
    $scope.noOrders = true;
    Orders.listOrderByVendor($localStorage.vendors[0].vendorId, keyword, page).then(
      function(result){
        $scope.orderList = result.content;
        if ( result.numberOfElements > 0 ) {
          $scope.noOrders = false;
        }
  
        // Pagination                                                                                                                                   
        $scope.totalItems = result.totalElements;
        $scope.currentPage = result.number + 1;
        $scope.maxSize = 7;
        $scope.itemsPerPage = result.size;
  
        $scope.pageChanged = function() {
          _loadOrders($scope.currentPage);
        };
        modal.close();
      },
      function (result) {
        $scope.messages.status = 'danger';
        $scope.messages.message = result;
        modal.close();
      }
    );
  };

  _loadOrders(1);

  var _openNews = function(id) {
    News.getNews(id).then(function(data){
      var modal = $uibModal.open({
        size: 'lg',
        templateUrl: "views/modal/news_detail.html",
        backdrop: "static",
        scope: $scope,
        controller : function($uibModalInstance ,$scope){
          $scope.news = data;
          $scope.content = $scope.news.newsContents;
          $scope.close = function() {
            $uibModalInstance.close();
          };
        }
      });
    })
  };

  var _searchOrder = function() {
    _loadOrders(1);
    $scope.isSearched = true;
  };

  var _openNewOrder = function() {
    $window.location.href = '/#/orders/';
  };

  var _clearResult = function() {
    $scope.searchWord = null;
    _loadOrders(1);
    $scope.isSearched = false;
  };

  $scope.openNews = _openNews;
  $scope.openNewOrder = _openNewOrder;
  $scope.searchByKeyword = _searchOrder;
  $scope.clearResult = _clearResult;
};

module.exports = dashboardController;

'use strict';

productSearchController.$inject = [
  '$uibModalInstance',
  '$scope',
  '$localStorage',
  '$uibModal',
  'Products',
];

function productSearchController($uibModalInstance, $scope, $localStorage, $uibModal, Products) {

  // Initialize
  $scope.versions = [
    { label: 'Movable Type 6', value: '6' },
    { label: 'Movable Type 7', value: '7' }
  ];
  $scope.serverTypes = [
    { label: 'nginx', value: 'nginx' },
    { label: 'Apache HTTPD', value: 'httpd' }
  ];

  var _listProducts = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });
    Products.listProducts($localStorage.vendors[0].vendorId, $scope.mtVersion, $scope.webServer).then(
      function(data){
        if (data && !data.message) {
          $scope.hideTable = false;
          $scope.products = data;
        } else {
          $scope.hideTable = true;
          $scope.products = null;
        }
        modal.close();
      },
      function(data){
        $uibModalInstance.dismiss(data.message);
        modal.close();
      }
    );
  };

  _listProducts();

  // Filter procut
  $scope.filterProductList = _listProducts;

  // Select Row
  $scope.selectRow = function(index) {
    if ($scope.selectedRow == index) {
      $scope.product = null;
    } else {
      $scope.product = $scope.products[index];
      $scope.selectedRow = index;
    }
  };

  // OK
  $scope.choose = function() {
    $uibModalInstance.close($scope.product);
  };

  // Cancel
  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };
};

module.exports = productSearchController;

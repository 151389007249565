'use strict';

var angular = require('angular');

angular.module('app').factory('Instances', require('./Instances'));
angular.module('app').factory('News', require('./News'));
angular.module('app').factory('Users', require('./Users'));
angular.module('app').factory('Products', require('./Products'));
angular.module('app').factory('Orders', require('./Orders'));
angular.module('app').factory('Customers', require('./Customers'));
angular.module('app').factory('CustomerPersons', require('./CustomerPersons'));

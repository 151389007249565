'use strict';

Customers.$inject = [
  '$rootScope',
  '$http',
  'API'
];

function Customers ( $rootScope, $http, API ) {
  var _loadCustomerList = function(page, params) {
    var url = API + '/api/customers/pages/' + page;

    return $http({
      method: 'get',
      url: url,
      params: params
    });
  };

  var _save = function(customerId, params) {
    var url = API + '/api/customers/';
    var method = 'post'
    if ( customerId ) {
      url += customerId + '/';
      method = 'put'
    }
    return $http({
      method: method,
      url: url,
      data: params
    });
  };

  var _loadCustomer = function(id) {
    var url = API + '/api/customers/' + id;

    return $http({
      method: 'get',
      url: url
    });
  }

  var _delete = function(customerId) {
    var url = API + '/api/customers/' + customerId;
    return $http({
      method: 'delete',
      url: url
    });
  };

  return {
    listCustomersByVendor: function(page, vendorId, keyword ) {
      return _loadCustomerList(page, vendorId, keyword ).then(function(res) {
        return res.data;
      });
    },
    save: function(customerId, params) {
      return _save(customerId, params).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    load: function(id) {
      return _loadCustomer(id).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    delete: function(id) {
      return _delete(id).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    }
  };
};

module.exports = Customers;

'use strict';

cancellationController.$inject = [
  '$uibModalInstance',
  '$scope',
  '$localStorage',
  '$uibModal',
  '$filter',
  'Orders',
  'params'
];

function cancellationController($uibModalInstance, $scope, $localStorage, $uibModal, $filter, Orders, params) {

  $scope.params = params;

  var _cancelOrder = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    Orders.cancelOrder($scope.orderId, $filter('date')($scope.orderExpiredDate, 'yyyy-MM-dd')).then(
      function(data){
        if ( data.message ) {
          modal.close();
          $uibModalInstance.dismiss(data.message);
        }
        else {
          modal.close();
          $uibModalInstance.close(data);
        }
      },
      function(data){
        modal.close();
        $uibModalInstance.dismiss(data.message);
      }
    )
  };

  // OK
  $scope.cancellation = _cancelOrder;

  // Cancel
  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };
};

module.exports = cancellationController;

'use strict';

orderController.$inject = [
  '$scope',
  '$localStorage',
  '$uibModal',
  '$routeParams',
  'Orders'
];
function orderController($scope, $localStorage, $uibModal, $routeParams, Orders) {

  //////////////////////////////////////////////////////////////////////
  // Initialize customer data
  var _initCustomer = function() {
    $scope.customer = {
      customerId: null,
      customerName: null
    };
  };

  //////////////////////////////////////////////////////////////////////
  // Initialize delivery customer data
  var _initDeliveryCustomer = function() {
    $scope.deliveryCustomer = {
      customerId: null,
      customerName: null,
      selected: false
    };
  };

  //////////////////////////////////////////////////////////////////////
  // Initialize customer person data
  var _initCustomerPerson = function() {
    $scope.customerPerson = {
      customerId: null,
      customerPersonId: null,
      customerPersonName: null,
      customerPersonDepartment: null,
      customerPersonEmail: null,
      customerPersonTel: null
    };
  };

  //////////////////////////////////////////////////////////////////////
  // Initialize delivery customer person data
  var _initDeliveryCustomerPerson = function() {
    $scope.deliveryCustomerPerson = {
      customerId: null,
      customerPersonId: null,
      customerPersonName: null,
      customerPersonDepartment: null,
      customerPersonEmail: null,
      customerPersonTel: null
    };
  };

  //////////////////////////////////////////////////////////////////////
  // Open Customer Search Modal Window
  var _searchCustomer = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/list_customer.html",
      backdrop: "static",
      scope: $scope,
      controller : 'customerSelectorModalController',
      resolve: {
        params: function() {
          return {
            searchFreeWord: $scope.searchCustomerName
          };
        }
      }
    });

    // Result
    modal.result.then(
      function(data){
        $scope.customer.customerId = data.id;
        $scope.customer.customerName = data.name;
        if ( $scope.customerPerson && $scope.customerPerson.customerId != data.id ) {
          _initCustomerPerson();
        }
      },
      function(result) {
        if ( result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Open Customer Search Modal Window
  var _searchDeliveryCustomer = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/list_customer.html",
      backdrop: "static",
      scope: $scope,
      controller : 'customerSelectorModalController',
      resolve: {
        params: function() {
          return {
            searchFreeWord: $scope.searchDeliveryCustomerName
          };
        }
      }
    });

    // Result
    modal.result.then(
      function(data){
        $scope.deliveryCustomer.customerId = data.id;
        $scope.deliveryCustomer.customerName = data.name;
        $scope.deliveryCustomer.selected = true

        if ( $scope.deliveryCustomerPerson.customerId && $scope.deliveryCustomerPerson.customerId != data.id ) {
          _initDeliveryCustomerPerson();
        }
      },
      function(result) {
        if ( result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Open Customer Person Search Modal Window
  var _searchCustomerPerson = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/list_customer_person.html",
      backdrop: "static",
      scope: $scope,
      controller : 'customerPersonModalController',
      resolve: {
        params: function() {
          return {
            customerId: $scope.customer.customerId,
            customerName: $scope.customer.customerName
          };
        }
      }
    });

    // Result
    modal.result.then(
      function(data){
        $scope.customerPerson = data;
      },
      function(result) {
        if ( result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Open Customer Person Search Modal Window
  var _searchDeliveryCustomerPerson = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/list_customer_person.html",
      backdrop: "static",
      scope: $scope,
      controller : 'customerPersonModalController',
      resolve: {
        params: function() {
          return {
            customerId: $scope.deliveryCustomer.customerId,
            customerName: $scope.deliveryCustomer.customerName
          };
        }
      }
    });

    // Result
    modal.result.then(
      function(data){
        $scope.deliveryCustomerPerson = data;
      },
      function(result) {
        if ( result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Open Product Search Modal Window
  var _searchProduct = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/list_product.html",
      backdrop: "static",
      scope: $scope,
      controller : 'productSearchController',
    });

    // Result
    modal.result.then(
      function(data){
        $scope.product = data;
      },
      function(result) {
        if ( result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Open New Customer Modal Window
  var _openCreateNewCustomer = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/create_customer.html",
      backdrop: "static",
      scope: $scope,
      controller : 'newCustomerController',
      resolve: {
        params: function() {
          return {
            target: '顧客'
          };
        }
      }
    });

    // Result
    modal.result.then(
      function(data){
        $scope.customer.customerId = data.customerId;
        $scope.customer.customerName = data.customerName;
        _initCustomerPerson();
      },
      function(result) {
        if ( result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Open New Delivery Customer Modal Window
  var _openCreateNewDeliveryCustomer = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/create_customer.html",
      backdrop: "static",
      scope: $scope,
      controller : 'newCustomerController',
      resolve: {
        params: function() {
          return {
            target: '提供先（販売店）'
          };
        }
      }
    });

    // Result
    modal.result.then(
      function(data){
        $scope.deliveryCustomer.customerId = data.customerId;
        $scope.deliveryCustomer.customerName = data.customerName;
        _initCustomerPerson();
      },
      function(result) {
        if ( result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Open New Customer Modal Window
  var _openCreateNewCustomerPerson = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/edit_customer_person.html",
      backdrop: "static",
      controller : 'editCustomerPersonController',
      resolve: {
        params: function() {
          return {
            customer: $scope.customer
          };
        }
      }
    });

    // Result
    modal.result.then(
      function(data){
        $scope.customerPerson = data;
      },
      function(result) {
        if ( result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Open New Delivery Customer Person Modal Window
  var _openCreateNewDeliveryCustomerPerson = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    // Open modal
    var modal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/edit_customer_person.html",
      backdrop: "static",
      controller : 'editCustomerPersonController',
      resolve: {
        params: function() {
          return {
            customer: $scope.deliveryCustomer
          };
        }
      }
    });

    // Result
    modal.result.then(
      function(data){
        $scope.deliveryCustomerPerson = data;
      },
      function(result) {
        if ( result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  };

  //////////////////////////////////////////////////////////////////////
  // Save order record
  var _save = function() {
    var data = {
      productId: $scope.product.productId,
      paymentTerm: $scope.paymentTerm,
      customerId: $scope.customer.customerId,
      customerPersonId: $scope.customerPerson.customerPersonId,
      date: orderDate.value,
      orderNumber: $scope.orderNumber,
      pronetDiscount: $scope.pronetDiscount,
      orderNoteForMaker: $scope.orderNoteForMaker,
      orderHostnameDesired: $scope.orderHostnameDesired
    }
    if ( $scope.deliveryCustomer.customerId != null) {
      data['deliveryCustomerId'] = $scope.deliveryCustomer.customerId;
      data['deliveryCustomerPersonId'] = $scope.deliveryCustomerPerson.customerPersonId
    }
    if ( $scope.contactCustomer == null ) {
      data['contactCustomer'] = 1;
    }
    else {
      data['contactCustomer'] = $scope.contactCustomer;
    }
    if ($scope.orderExpiredDate) {
      data.orderExpiredDate = $filter('date')($scope.orderExpiredDate, 'yyyy-MM-dd');
    } else {
      data.orderExpiredDate = '';
    }

    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    Orders.save($scope.orderId, data).then(function(result){
      modal.close();
      if (result.message == undefined) {
        $scope.messages.status = 'success';
        if ( $scope.editMode ) {
          $scope.messages.message = '注文を保存しました。';
        }
        else {
          $scope.messages.message = '注文を受け付けました。(注文番号:' + result.orderCode + ')';
          _initialize();
        }
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = result.message;
      }
    })
  };

  //////////////////////////////////////////////////////////////////////
  // CHange status to production
  var _changeToProduction = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller : function($uibModalInstance, $scope){
        $scope.title = "本契約への切り替え";
        $scope.messageBody = "本契約に切り替えます。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function () {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      Orders.changeToProduction($scope.orderId).then(function(data){
        modal.close();
        if (data.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = "本契約に切り替えました。";
          _expandData(data);
          _resetAction(data);
        }
        else {
          $scope.messages.status = 'danger';
          $scope.messages.message = data.message;
        }
      })
    }, function () {
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Delete order
  var _delete = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller : function($uibModalInstance, $scope){
        $scope.title = "注文の削除";
        $scope.messageBody = "この注文を削除します。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function () {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      Orders.deleteOrder($scope.orderId).then(function(data){
        modal.close();
        if (data.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = "注文を削除しました。";
          _expandData(data);
          _resetAction(data);
        }
        else {
          $scope.messages.status = 'danger';
          $scope.messages.message = data.message;
        }
      })
    }, function () {
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Cancel order
  var _cancellation = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller : function($uibModalInstance, $scope){
        $scope.title = "注文の解約";
        $scope.messageBody = "この注文を解約します。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    modal.result.then(function () {
      // Open modal
      var modal = $uibModal.open({
        size: 'lg',
        templateUrl: "views/modal/cancellation.html",
        backdrop: "static",
        scope: $scope,
        controller : 'cancellationController',
        resolve: {
          params: function() {
            return {
              orderId: $scope.orderId,
              orderCode: $scope.orderCode,
              orderNumber: $scope.orderNumber
            };
          }
        }
      });

      // Result
      modal.result.then(
        function(data){
          $scope.messages.status = 'success';
          $scope.messages.message = "注文を解約しました。";
          _expandData(data);
          _resetAction(data);
        },
        function(result) {
          if ( result != 'cancel') {
            $scope.messages.status = 'danger';
            $scope.messages.message = result;
          }
        }
      );

    }, function () {
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Reactivate order
  var _reactivation = function() {
    $scope.messages.status = '';
    $scope.messages.message = '';

    var modal = $uibModal.open({
      templateUrl: "views/modal/confirm.html",
      backdrop: "static",
      keyboard: false,
      scope: $scope,
      controller : function($uibModalInstance, $scope){
        $scope.title = "解約の取り消し";
        $scope.messageBody = "この注文の解約を取り消して本契約に戻します。よろしいですか？";
        $scope.okay = function() {
          $uibModalInstance.close();
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });

    modal.result.then(function () {
      // Do destroy
      var modal = $uibModal.open({
        templateUrl: "views/modal/progress.html",
        backdrop: "static",
        keyboard: false
      });

      Orders.reactivateOrder($scope.orderId).then(function(data){
        modal.close();
        if (data.message == undefined) {
          $scope.messages.status = 'success';
          $scope.messages.message = "解約を取り消しました。";
          _expandData(data);
          _resetAction(data);
        }
        else {
          $scope.messages.status = 'danger';
          $scope.messages.message = data.message;
        }
      })
    }, function () {
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Change edit mode
  var _editMode = function() {
    $scope.editMode = true;
  };

  //////////////////////////////////////////////////////////////////////
  // Initialize
  var _initialize = function() {
    $scope.orderDate = new Date();
    $scope.contactCustomer = 1;
    $scope.product = null;
    $scope.orderNumber = null;
    $scope.paymentTerm = null;
    $scope.pronetDiscount = null;

    _initCustomer();
    _initDeliveryCustomer();
    _initCustomerPerson();
    _initDeliveryCustomerPerson();

  };

  //////////////////////////////////////////////////////////////////////
  // Expand order data to scope
  var _expandData = function(data) {
    $scope.product = data.product;
    $scope.orderDate = new Date(data.orderDate);
    $scope.orderNumber = data.orderNumber;
    $scope.orderCode = data.orderCode;
    $scope.orderStatus = data.orderStatus;
    $scope.orderActivationDate = data.orderActivationDate;
    $scope.orderExpiredDate = data.orderExpiredDate;
    $scope.sellerName = data.seller && data.seller.sellerName || '';
    $scope.orderNoteForMaker = data.orderNoteForMaker;
    $scope.orderNoteForReseller = data.orderNoteForReseller;
    $scope.orderHostnameDesired = data.orderHostnameDesired;

    $scope.customer = data.customer;
    if ( $scope.customer == null )
      _initCustomer();
    else
      $scope.customer.selected = true

    $scope.deliveryCustomer = data.deliveryCustomer;
    if ( $scope.deliveryCustomer == null)
      _initDeliveryCustomer();
    else
      $scope.deliveryCustomer.selected = true

    $scope.customerPerson = data.customerPerson;
    if ( $scope.customerPerson == null )
      _initCustomerPerson();

    $scope.deliveryCustomerPerson = data.deliveryCustomerPerson;
    if ( $scope.deliveryCustomerPerson == null )
      _initDeliveryCustomerPerson();

    $scope.pronetDiscount = data.orderPronetDiscount;
    if ( data.orderPronetDiscount == 1)
      $scope.orderPronetDiscount = 'Pronet 割引を適用する';

    $scope.paymentTerm = data.orderPaymentTerm;
    if ( data.orderPaymentTerm == 0 )
      $scope.orderPaymentTerm = '不明';
    else if( data.orderPaymentTerm == 1 )
      $scope.orderPaymentTerm = '月額';
    else if( data.orderPaymentTerm == 2 )
      $scope.orderPaymentTerm = '3ヶ月';
    else if( data.orderPaymentTerm == 3 )
      $scope.orderPaymentTerm = '年額';
    else if( data.orderPaymentTerm == 4 )
      $scope.orderPaymentTerm = '無償';

    $scope.contactCustomer = data.contactCustomerType + '';
    if ( data.contactCustomerType == 0) {
      $scope.orderDeliveryCotactCustomer = '主たる連絡先とする';
      $scope.orderCotantCustomer = '';
    }
    else {
      $scope.orderCotantCustomer = '主たる連絡先とする';
      $scope.orderDeliveryCotactCustomer = '';
    }
};

  //////////////////////////////////////////////////////////////////////
  // Reset action bar
  var _resetAction = function(data) {
    $scope.action = null;
    if ( data.orderStatus == 1) {
      // Enable
      $scope.action = {};
      $scope.action.canEdit = true;
      if ( data.orderExpiredDate ) {
        $scope.action.canCancel = false;
        $scope.action.canReactivate = true;
      } else {
        $scope.action.canCancel = true;
        $scope.action.canReactivate = false;
      }
      $scope.isProduction = true;
    }
    else if ( data.orderStatus == 2 ) {
      // Draft
      $scope.action = {};
      $scope.action.canEdit = true;
      $scope.action.canDoProduction = true;
      $scope.action.canDelete = true;
    }
    else if ( data.orderStatus == 3) {
      // Canceled
      $scope.action = {};
      $scope.action.canReactivate = true;
    }
    else if ( data.orderStatus == 4) {
      // Canceling
      $scope.action = {};
      $scope.action.canReactivate = true;
    }
};

  //////////////////////////////////////////////////////////////////////
  // Loading order
  var _loadOrder = function() {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });
    $scope.orderId = $routeParams.id;
    Orders.getOrder($routeParams.id).then( function(data) {
      modal.close();
      if (data.message == undefined) {
        _expandData(data);
        _resetAction(data);
      } else {
        $scope.messages.status = 'danger';
        $scope.messages.message = data.message;
      }
    });
  };

  //////////////////////////////////////////////////////////////////////
  // Bind functions
  $scope.searchCustomer = _searchCustomer;
  $scope.searchDeliveryCustomer = _searchDeliveryCustomer;
  $scope.clearCustomer = _initCustomer;
  $scope.clearDeliveryCustomer = _initDeliveryCustomer;
  $scope.searchCustomerPerson = _searchCustomerPerson;
  $scope.clearCustomerPerson = _initCustomerPerson;
  $scope.searchDeliveryCustomerPerson = _searchDeliveryCustomerPerson;
  $scope.clearDeliveryCustomerPerson = _initDeliveryCustomerPerson;
  $scope.searchProduct = _searchProduct;
  $scope.openCreateNewCustomer = _openCreateNewCustomer;
  $scope.openCreateNewDeliveryCustomer = _openCreateNewDeliveryCustomer;
  $scope.openCreateNewCustomerPerson = _openCreateNewCustomerPerson;
  $scope.openCreateNewDeliveryCustomerPerson = _openCreateNewDeliveryCustomerPerson;
  $scope.save = _save;
  $scope.edit = _editMode;
  $scope.production = _changeToProduction;
  $scope.delete = _delete;
  $scope.cancellation = _cancellation;
  $scope.reactivation = _reactivation;

  //////////////////////////////////////////////////////////////////////
  // Initialization
  $scope.messages = {
    status: '',
    message: '',
  };
  $scope.terms = [
    { label: '月額', value: 1},
    { label: '年額', value: 3}
  ];
  $scope.action = null;

  $scope.editMode = false;
  _initialize();

  if ($routeParams.id)
    _loadOrder();
};

module.exports = orderController;

'use strict';

customerPersonModalController.$inject = [
  '$uibModalInstance',
  '$scope',
  '$localStorage',
  '$uibModal',
  'CustomerPersons',
  'params'
];

function customerPersonModalController($uibModalInstance, $scope, $localStorage, $uibModal, CustomerPersons, params) {

  $scope.customerId = params.customerId;
  $scope.customerName = params.customerName;

  var _listCustomerPersons = function(customerId) {
    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });
    CustomerPersons.listCustomerPersonList(customerId).then(
      function(data){
        if (data && data.length > 0) {
          $scope.hideTable = false;
          $scope.customerPersons = data;
        } else {
          $scope.hideTable = true;
        }
        modal.close();
      },
      function(data){
        $uibModalInstance.dismiss(data.message);
        modal.close();
      }
    );
  };

  _listCustomerPersons(params.customerId);

  // Select Row
  $scope.selectRow = function(index) {
    if ($scope.selectedRow == index) {
      $scope.customerPerson = null;
      $scope.customerPersonId = null;
      $scope.customerPersonName = null;
      $scope.customerPersonDepartment = null;
      $scope.customerPersonTel = null;
      $scope.customerPersonEmail = null;
      $scope.selectedRow = null;
    } else {
      $scope.customerPerson = $scope.customerPersons[index];
      $scope.customerPersonId = $scope.customerPerson.customerPersonId;
      $scope.customerPersonName = $scope.customerPerson.customerPersonName;
      $scope.customerPersonDepartment = $scope.customerPerson.customerPersonDepartment;
      $scope.customerPersonTel = $scope.customerPerson.customerPersonTel;
      $scope.customerPersonEmail = $scope.customerPerson.customerPersonEmail;
      $scope.selectedRow = index;
    }
  };

  // OK
  $scope.choose = function() {
    $uibModalInstance.close($scope.customerPerson);
  };

  // Cancel
  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };
};

module.exports = customerPersonModalController;

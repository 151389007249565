'use strict';

Instances.$inject = [
  '$rootScope',
  '$http',
  'API'
]

function Instances( $rootScope, $http, API ) {
  var _listInstances = function() {
    var url = API + '/api/instances/';
    return $http({
      method: 'get',
      url: url,
      cache: true
    });
  };

  var _getInstance = function(id) {
    var url = API + '/api/instances/' + id;
    return $http({
      method: 'get',
      url: url
    });
  };

  return {
    listInstances: function() {
      return _listInstances().then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    },
    getInstance: function(id) {
      return _getInstance(id).then(
        function(res){
          return res.data;
        },
        function(res){
          return res.data;
        }
      );
    }
  };
};

module.exports = Instances;

'use strict';

authController.$inject = [
  '$scope',
  '$location',
  '$window',
  '$localStorage',
  '$routeParams',
  '$q',
  'AuthService',
  'Users'
];

function authController($scope, $location, $window, $localStorage, $routeParams, $q, AuthService, Users) {
  $scope.message = '';
  $localStorage.$reset();

  if ( $location.path() === '/reset_password') {
    $scope.newPassword = '';
    $scope.retypePassword = '';
  
    $scope.token = $routeParams.token
  
    $scope.canChange = true;
    if ( !$scope.token ) {
      $scope.canChange = false;
      $scope.message = '無効なリクエストです。'
    }
  
    var _resetPassword = function() {
      if ( $scope.newPassword != $scope.retypePassword ) {
        $scope.message = '入力されたパスワードが違います。'
      }
      else {
        Users.resetPassword($scope.token, $scope.newPassword)
        .then( function(res){
          if (res.message) {
            $scope.message = res.message;
          }
          else {
            $location.search({});
            $location.path('/password_reset_done');
          }
        });
      }
    };
  
    $scope.resetPassword = _resetPassword;
  }
  else {
    var _signIn = function(){
      $scope.message = '';

      AuthService.signIn($scope.username, $scope.password)
        .then( function (res) {
          var token = res.data.token;
          var base64Url = token.split('.')[1];
          var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          var parsed = JSON.parse($window.atob(base64));

          $localStorage.$default({
            username: $scope.username,
            password: $scope.password,
            token: token,
            tokenParsed: parsed,
            isAuthed: true
          });

          return Users.getUser('me');
        })
        .then ( function(res) {
          $localStorage.$default({
            user: res.data
          });
          return Users.getVendors(res.data.accountId)
        })
        .then( function(res) {
          if ( res.data.length == 0) {
            $q.reject();
          }
          $localStorage.$default({
            vendors: res.data
          });
          $location.path('/dashboard');
        })
        .catch( function(res) {
          $localStorage.$reset();
          $scope.message = "認証に失敗しました。";
        });
    };

    var _forgotPassword = function() {
      $location.path('/forgot');
    };

    var _recover = function() {
      Users.passwordResetReuqest($scope.email)
      .then( function onSuccess(res){
        $location.path('/forgot_request_done');
      }, function onError(res) {
        $location.path('/forgot_request_done');
      });
    };

    var _moveTop = function() {
      $location.path('/signin');
    };

    $scope.signIn = _signIn;
    $scope.forgotPassword = _forgotPassword;
    $scope.recover = _recover;
    $scope.moveTop = _moveTop;
  }
}

module.exports = authController;

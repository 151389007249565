'use strict';

var angular = require('angular');

angular.module('app').controller('authController', require('./authController'));
angular.module('app').controller('dashboardController', require('./dashboardController'));
angular.module('app').controller('navController', require('./navController'));
angular.module('app').controller('userController', require('./userController'));
angular.module('app').controller('orderController', require('./orderController'));
angular.module('app').controller('customerSelectorModalController', require('./customerSelectorModalController'));
angular.module('app').controller('customerPersonModalController', require('./customerPersonModalController'));
angular.module('app').controller('productSearchController', require('./productSearchController'));
angular.module('app').controller('newCustomerController', require('./newCustomerController'));
angular.module('app').controller('editCustomerPersonController', require('./editCustomerPersonController'));
angular.module('app').controller('cancellationController', require('./cancellationController'));
angular.module('app').controller('manualController', require('./manualController'));
angular.module('app').controller('customerListController', require('./customerListController'));
angular.module('app').controller('customerController', require('./customerController'));

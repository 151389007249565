'use strict';

userController.$inject = [
  '$scope',
  '$routeParams',
  '$localStorage',
  '$uibModal',
  'Users'
];

function userController($scope, $routeParams, $localStorage, $uibModal, Users) {
  $scope.displayName = $localStorage.tokenParsed.aud;
  $scope.editMode = false;

  var userId = $routeParams.id;

  var modal = $uibModal.open({
    templateUrl: "views/modal/progress.html",
    backdrop: "static",
    keyboard: false
  });

  Users.getUser(userId).then(function(res){
    $scope.user = res.data;
    modal.close();
  });

  $scope.updateUserInfo = function() {
    $scope.messages = {
      status: '',
      message: ''
    };

    var modal = $uibModal.open({
      templateUrl: "views/modal/progress.html",
      backdrop: "static",
      keyboard: false
    });

    // Convert to upload data structure
    var accountData = {
      displayName: $scope.user.accountDisplayName,
      email: $scope.user.accountEmail
    };

    Users.save($scope.user.accountId, accountData)
      .then(
        function onSuccess(result) {
          $scope.user = result.data;
          $scope.editMode = false;
          modal.close();
          $scope.messages.status = 'success';
          $scope.messages.message = "ユーザー情報を更新しました。";
        },
        function onError(result) {
          modal.close();
          $scope.messages.status = 'danger';
          $scope.messages.message = "ユーザー情報を更新できませんでした。";
        }
      );
  };

  $scope.openNewPassword = function() {
    $scope.messages = {
      status: '',
      message: ''
    };

    var passwordModal = $uibModal.open({
      size: 'lg',
      templateUrl: "views/modal/change_password.html",
      backdrop: "static",
      scope: $scope,
      controller : function($uibModalInstance ,$scope, Users){
        $scope.currentPassword = '';
        $scope.newPassword = '';
        $scope.retypePassword = '';

        $scope.change = function() {
          var params = {
            newPassword: $scope.newPassword,
            currentPassword: $scope.currentPassword
          };

          $scope.messages.status = '';
          $scope.messages.message = '';
          var modal = $uibModal.open({
            templateUrl: "views/modal/progress.html",
            backdrop: "static",
            keyboard: false
          });

          Users.changePassword($scope.user.accountId, params).then(
            function onSuccess(result) {
              $scope.user = result.data;
              $localStorage.password = $scope.newPassword;
              modal.close();
              $uibModalInstance.close();
            },
            function onError(result) {
              modal.close();
              $uibModalInstance.dismiss(result.data.message);
            }
          );
        };

        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });
    passwordModal.result.then(
      function () {
        // Saved
        $scope.messages.status = 'success';
        $scope.messages.message = "パスワードを更新しました。";
      },
      function (result) {
        if (result != 'cancel') {
          $scope.messages.status = 'danger';
          $scope.messages.message = result;
        }
      }
    );
  }
};

module.exports = userController;
